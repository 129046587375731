<template>
  <div>
    <AboutUsHero title="Leadership" />

    <MemberPortfolioModalVue v-if="showModal" :member="viewingMember" @close="close" />

    <div class="my-5 px-5 d-flex flex-column" style="gap: 20px">
      <b-row v-for="(leader, i) in leadership" :key="i">
        <b-col sm="12" md="3">
          <b-img
            fluid
            style="width: 300px"
            rounded=""
            :src="leader.img"
          />
        </b-col>
        <b-col sm="12" md="6">
          <h2 class="">{{ leader.name }}</h2>
          <h4 class="mb-2">
            {{ leader.position }}
          </h4>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div v-html="leader.bio"></div>
        </b-col>
      </b-row>

      <div class="sub-leader-section mt-5">
        <div class="leader-header" style="border-bottom: 2px solid darkblue;">
          <h1 class="leader-h1">National Executive Council (NEC)</h1>
        </div>

        <swiper
          class="swiper-multiple mt-2"
          :options="swiperOptions"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        >
          <swiper-slide
            v-for="(data, index) in nec_members"
            :key="'nec' + index"
          >
            <div>
              <div class="img-container">
                <b-img
                  :src="data.img"
                  fluid
                />
              </div>

              <div class="member-details px-2 pt-2">
                <h3>{{ data.name }}</h3>
                <p>{{ data.position }}</p>
              </div>
            </div>
          </swiper-slide>

          <!-- Add Arrows -->
          <div
            slot="button-next"
            class="swiper-button-next"
          />
          <div
            slot="button-prev"
            class="swiper-button-prev"
          />
        </swiper>
      </div>

      <div class="sub-leader-section mt-5">
        <div class="leader-header" style="border-bottom: 2px solid darkblue;">
          <h1 class="leader-h1">National Coordinating Team (NCT)</h1>
        </div>

        <p class="text-lg my-2">
          The National Coordinating Team is a 9-member committee comprising the Coordinator, the Secretary, the Financial Secretary, the Organizing Secretary, the Treasurer, the Prayer Secretary and three Executive Members. It serves as the policy implementation body of the NCC and represents PENSA-Ghana at all meetings. 
          Below are the NCT members for the year 2023. 
        </p>

        <swiper
          class="swiper-multiple mt-2"
          :options="swiperOptions"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        >
          <swiper-slide
            v-for="(data, index) in nct_members"
            :key="'ntc' + index"
          >
            <div class="cursor-pointer" @click="showMemberInfo(data)">
              <div class="img-container">
                <b-img
                  :src="data.img"
                  fluid
                />
              </div>

              <div class="member-details px-2 pt-2 d-flex justify-content-between align-items-center">
                <div>
                  <h3>{{ data.name }}</h3>
                  <p>{{ data.position }}</p>
                </div>

                <div>
                  <p class="text-info font-md" style="font-size: 14px; font-weight: bold;">Read More</p>
                </div>
              </div>
              
            </div>
          </swiper-slide>

          <!-- Add Arrows -->
          <div
            slot="button-next"
            class="swiper-button-next"
          />
          <div
            slot="button-prev"
            class="swiper-button-prev"
          />
        </swiper>
      </div>

      <div class="sub-leader-section mt-5">
        <div class="leader-header" style="border-bottom: 2px solid darkblue;">
          <h1 class="leader-h1">Committee Heads</h1>
        </div>

        <swiper
          class="swiper-multiple mt-2"
          :options="swiperOptions"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        >
          <swiper-slide
            v-for="(data, index) in committee_heads"
            :key="index"
          >
            <div>
              <div class="img-container">
                <b-img
                  :src="data.img"
                  fluid
                />
              </div>

              <div class="member-details px-2 pt-2">
                <h3>{{ data.name }}</h3>
                <p>{{ data.position }}</p>
              </div>
            </div>
          </swiper-slide>

          <!-- Add Arrows -->
          <div
            slot="button-next"
            class="swiper-button-next"
          />
          <div
            slot="button-prev"
            class="swiper-button-prev"
          />
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BLink,
  BImg,
  BCard,
  BCardText,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
} from "bootstrap-vue";

import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css'

import AboutUsHero from "@/@core/components/public/AboutUsHero.vue";
import MemberPortfolioModalVue from '@/@core/components/leadership/MemberPortfolioModal.vue';

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BImg,
    BCard,
    BCardText,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    AboutUsHero,
    MemberPortfolioModalVue,

    Swiper,
    SwiperSlide
  },
  data() {
    return {
      showModal: false,
      viewingMember: null,
      leadership: [
        {
          // eslint-disable-next-line global-require
          img: require('@/assets/images/public/pensa_img_4.jpg'),
          name: 'Apostle Ebenezer Hagan',
          position: 'Director, Youth Ministry',
          bio: `
            <p class="text-lg mb-1">
              Apostle Ebenezer Hagan is the Youth Director of the Church of Pentecost and the Director of PENSA Ghana. He is a PhD student at the Talbot School of Theology, Biola University, California, USA.
            </p>
            <p class="text-lg mb-1">
              He holds a Master of Theology from the Trinity Theological Seminary, Legon-Ghana, an MA in Pentecostal Studies from Pentecost University, and a BSc. in Civil Engineering from KNUST.  
              He is a member of the Association of Youth Ministry Educators the International Association for the Study of Youth Ministry and Society for Professors of Christian Education.  He is married to Priscilla and they are blessed with three girls. 
            </p>
          `
        },
        {
          // eslint-disable-next-line global-require
          img: require('@/assets/images/public/pensa_img_5.jpg'),
          name: 'Eld Prof Eric Appau Asante',
          position: 'Deputy Director',
          bio: `
            <p class="text-lg mb-1">Elder Prof. Eric Appau Asante is the departmental head of Educational Innovations in Science and Technology and a senior lecturer at the Kwame Nkrumah University of Science and Technology (KNUST).</p>
            <p class="text-lg mb-1">
              He is the Presiding Elder of Atonsu Central Assembly, a member of the Asokwa Area Executive Committee of the Church and the Chairman of the Asokwa Area Council Counselling Committee.
              <br /><br />He was the assistant Area Youth Leader before his appointment as the Deputy Youth Director.
              <br /><br />He is beautifully married to Deaconess Sally and are blessed with four children. 
              <br /><br />His interests are listening to music, photography, playing guitar and research.
            </p>
          `
        },
        {
          // eslint-disable-next-line global-require
          img: require('@/assets/images/public/pensa_img_6.jpg'),
          name: 'Pastor Gordon Ansah',
          position: 'National Youth Secretary / Upper West Traveling secretary ',
          bio: ''
        },
      ],
      nec_members: [
        {
          img: '/nec/1.jpg',
          name: 'Apostle Ebenezer Hagan',
          position: 'Director, Youth Ministry',
        },
        {
          img: '/nec/2.jpg',
          name: 'Eld Prof Eric Appau Asante',
          position: 'Deputy Director',
        },
        {
          img: '/nec/3.jpg',
          name: 'Pastor Gordon Ansah',
          position: 'National Youth Secretary / Upper West Traveling secretary',
        },
        {
          img: '/nec/4.png',
          name: 'Pastor Philip Pascal Asiedu',
          position: 'National Executive Committee Member',
        },
        {
          img: '/nec/5.png',
          name: 'Mrs Priscilla Yirebi',
          position: 'National Executive Committee Member',
        },
        {
          img: '/nec/6.png',
          name: 'Dns Mrs Comfort W. Adade',
          position: 'National Executive Committee Member',
        },
        {
          img: '/nec/7.png',
          name: 'Elder Joseph Selorm Tetteh',
          position: 'National Executive Committee Member',
        },
        {
          img: '/nec/8.png',
          name: 'Elder Boahen Samuel',
          position: 'PENSA Ghana Rep',
        },
      ],
      nct_members: [
        {
          img: '/nct/6.jpg',
          name: 'Elder Samuel Boahen',
          position: 'Coordinator',
          tel: '0245690805 / 0206039212',
          email: 'samgr8faith@gmail.com',
          bio: `
            Elder Samuel Boahen is the Pensa Ghana Coordinator -2023. He has throughout his studies in Opoku Ware School, Univ. Of Ghana, College of Health, Kimtampo and Catholic University been involved in PENSA and has served in several portfolios including Prayer Secretaty, Vice President and President at various times. 
            He is licensed Physician Assistant - Medical, currently pursuing a Masters in Public Health - Monitory and Evaluation. 
            He is a member of the New Juaben District (Koforidua Area) where he serves as the chairman of the District Discipleship and Leadership Development Committee. 
            He is married to Deborah and they're blessed with two children, Nyamebasa and Nyamedea.
          `
        },
        {
          img: '/nct/5.jpg',
          name: 'Eld Isaiah K. Ahorsu',
          position: 'PENSA GH Secretary',
          tel: '0545296755',
          email: 'ahorsukwabla@gmail.com',
          bio: `
            Elder Isaiah Kwabla Ahorsu is the current Pensa Ghana Secretary and Sector Head for Upper West Sector.
            He is a final year student of University for Development Studies ( UDS WA Campus) pursuing Bachelor of Education Social Science - Geography Option. He is an officer of The Church of Pentecost Buipe District English Assembly in the Kintampo Area.
          `
        },
        {
          img: '/nct/4.jpg',
          name: 'Eld Emmanuel Katakyie',
          position: 'PENSA GH Financial Secretary',
          tel: '0246671282',
          email: 'ekatakyie024667@gmail.com',
          bio: `
            Eld Emmanuel Katakyie is the current PENSA Ghana financial secretary. He is a former Vice President of PENSA UDS Wa. He is a 27 year old man from Akim Afosu in the Eastern Region. He holds a Certificate in Theology and pastoral Studies, Degree in BSE Accounting from University for Development Studies Wa Campus. He fellowships at the Church of Pentecost, Ankaako Assembly, Wawase District in the Twifo Praso Area. He is an executive member of the Twifo Praso Area Media Team and a district youth executive member.
          `
        },
        {
          img: '/nct/3.jpg',
          name: 'Eld Ebenezer A. Bekoe',
          position: 'PENSA GH Prayer Secretary',
          tel: '02450146143 / 0500397571',
          email: 'bekoeebenezer44@gmail.com',
          bio: `
            Eld. Ebenezer A. Bekoe is currently serving as the Pensa Ghana prayer secretary. He is a former Pensa President at the University for Development Studies and a Sector Head. He has obtained Certificate in Theology and Pastoral Studies, Bed. Social Science (Economics option), and Presently, Eld. Bekoe additionally is, area youth executive member in Kwadaso area, District executive member and District Youth Leader, and Presiding Elder of The Church of Pentecost, Hemang Assembly, Apire District in the Kwadaso Area.
          `
        },
        {
          img: '/nct/7.jpg',
          name: 'Rita Rilla Amoah',
          position: 'Treasure',
          tel: '0240234990',
          email: 'ritarillaamoah178@gmail.com',
          bio: `
           Rita Rilla Amoah is the current PENSA Ghana Treasurer. She is a former Ladies Wing Vice President PENSA UDS Wa. She is from Ekumfi Ekumpoano in the Central region of Ghana. She holds a Diploma in Business Studies and a Bachelor's Degree in Commerce (Finance) both from University for Development Studies Wa Campus. She fellowships at the Church of Pentecost, English Assembly, Mataheko District in the Kaneshie Area.
          `
        },
         {
          img: '/nct/9.png',
          name: 'Eld. Nana Yaw Barnie Elvis',
          position: 'Organizer',
          tel: '0553725355',
          email: 'elvisbarnie@gmail.com',
          bio: `
           Eld. N.Y.E. Barnie is currently serving as the Pensa Ghana Organizer. He brings a wealth of a former Pensa President at the University of Energy and Natural Resources and a Sector Financial Secretary. He has obtained Certificate in Theology and Pastoral Studies, BSc in Environmental Engineering, and Presently engaged in MSc Environmental Engineering Management, Eld. Elvis additionally is, the District Youth Secretary and Local Secretary at the Church of Pentecost, Mmeredane English Assembly, Sunyani Area.
          `
        },
        {
          img: '/nct/8.jpg',
          name: 'Robert Balosu Bakuoro',
          position: 'Executive Member',
          tel: '0554059512',
          email: 'robertbakuoro@gmail.com',
          bio: `
            He's a former Schools Coordinator of PENSA UDS Tamale. He's 27 years old man from Dindee, Lambussie. He holds a Bachelors Degree in Basic Education from the University for Development Studies, Tamale.
            <br /><br />He fellowships with the Church of Pentecost,Tumu Area,Dindee Assembly where he serves as the presiding Elder.
            <br /><br />He's also the current Area Assistant Leader of the children ministry as well as Fielmoa District children ministry Leader. He's happily married to Mavis Taawah
          `
        },
        {
          img: '/nct/2.jpg',
          name: 'Eld Dr Solomon Osei Amankwah',
          position: 'Executive Member',
          tel: '0261536978',
          email: 'oseiamankwaahs@gmail.com',
          bio: `
            Eld. Dr. Solomon Osei Amankwaah is an Executive Member of the PENSA GHANA Co-ordinating team. He is an Intern, Medical Laboratory Scientist with the Komfo Anokye Teaching Hospital and he is 24 years of age.
            He holds a Doctor of Medical Laboratory Science degree from the University For Development Studies(UDS). At UDS, he served as the President of PENSA and also as Sector Coordinator for the Northern Sector. 
            He is a member of the Allied Health Professions Council and the Ghana Association of Medical Laboratory Scientists(GAMLS). He is currently the District Youth Secretary of the Bohyen District Youth Ministry in the Suame Area of the Church of Pentecost.
          `
        },
        {
          img: '/nct/1.jpg',
          name: 'Comfort Serwaa A.',
          position: 'Executive Member',
          tel: '0507830545',
          email: 'serwaacomfort222@gmail.com',
          bio: `
            Ahimah Serwaa Comfort is an Executive Member of PENSA Ghana. She is a former Secretary of PENSA UDS Navrongo and the Sector Secretary of the Upper East Sector. She is a 24 year old lady from Krofa in the Ashanti Region. 
            She holds a Degree in Computer Science from University for Development Studies, Navrongo Campus. She fellowships at the Church of Pentecost, New Dormaa Assembly-New Dormaa District in the Sunyani Area.
            <br /> <br />Comfort doubles as an Executive member of the Local and District Youth Executives and also a member of the District Virtuous Ladies Executive.She is currently a National Service Personnel at the Head office of Odomase No.1 Traditional Council -Sunyani West.
          `
        },
      ],
      committee_heads: [
        {
          img: '/committee/1.jpg',
          name: 'Dr.  Michael Ofoe Tetteh',
          position: 'Technical Committee Head',
        },
        {
          img: '/committee/2.jpg',
          name: 'Elder Amoh Dornor',
          position: 'Special Duties Committee Head',
        },
        {
          img: '/committee/3.jpg',
          name: 'ELDER CYRUS ANTW-AGYEI AMPONG DAVID',
          position: 'Organizing Committee Head',
        },
        {
          img: '/committee/4.jpg',
          name: 'Elder Emmanuel Owusu Amful',
          position: 'IT Head',
        },
        {
          img: '/committee/5.jpg',
          name: 'Elder Ernest Amo Agyemang',
          position: 'Pre-tertiary Committee Head',
        },
        {
          img: '/committee/6.png',
          name: 'Elder Joseph Asiamah-Mantei',
          position: 'Sponsorship Committee Head',
        },
        {
          img: '/committee/7.jpg',
          name: 'Eric Okoto (Eld)',
          position: 'Home PENSA Committee',
        },
        {
          img: '/committee/8.jpg',
          name: 'Jennifer Otoo Paintsil',
          position: 'Protocol',
        },
        {
          img: '/committee/9.jpg',
          name: 'Jonas Kwabena Asante-Krobea (Elder)',
          position: 'Schools Outreach Coordinator',
        },
        {
          img: '/committee/10.jpg',
          name: 'Richmond Nana Kwame Osei',
          position: 'Pensa Literary head',
        },
        {
          img: '/committee/11.png',
          name: 'Dns. Mabel Akutey',
          position: 'Persons With Disability Committee',
        },
        {
          img: '/committee/12.png',
          name: 'Dn Obed Yaw Mensah',
          position: 'Media and Publicity Committee',
        }
      ],
      swiperOptions: {
        slidesPerView: 3,
        spaceBetween: 30,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        breakpoints: {
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 100,
          },
        }
      },
    }
  },
  methods: {
    close() {
      this.showModal = false;
      this.viewingMember = null;
    },
    showMemberInfo(member) {
      this.viewingMember = member;
      this.showModal = true;
    }
  }
};
</script>

<style lang="scss">
.sub-leader-section {
  position: relative;
}

.leader-h1 {
  font-size: 25px;
  font-weight: bold;
  // font-style: italic;
  color: blue;
  &::after {
    content: '';
    border: 5px solid darkblue;
    width: 400px;
    height: 1px;
    position: absolute;
    top: 33px;
    left: 15px;
  }

  @media (max-width: 576px) {
    &::after {
      display: none;
    }
  }
}

.img-container {
  height: 200px;
  img {
    object-fit: contain !important;
  }
}
.member-details {
  height: 100px;
  background-color: blue;
  width: 100%;
  * {
    color: white;
  }
  padding: 5px;
  border-top-left-radius: 50px;
  border-bottom-right-radius: 50px;
}

// h1 {
//   font-size: 3rem;
//   font-weight: normal;
// }

.text-yellow {
  color: yellow;
}
.text-lg {
  font-size: 15px;
}
.img-container {
  height: 400px;
  widows: 300px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

ol {
  font-size: 15px;
  li {
    margin-bottom: 10px;
  }
}

/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
  .w-sm-100 {
    width: 100% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-25 {
    width: 25% !important;
  }
  .h-sm-100 {
    height: 100% !important;
  }
  .h-sm-75 {
    height: 75% !important;
  }
  .h-sm-50 {
    height: 50% !important;
  }
  .h-sm-25 {
    height: 25% !important;
  }
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  .w-md-100 {
    width: 100% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-25 {
    width: 25% !important;
  }
  .h-md-100 {
    height: 100% !important;
  }
  .h-md-75 {
    height: 75% !important;
  }
  .h-md-50 {
    height: 50% !important;
  }
  .h-md-25 {
    height: 25% !important;
  }
}

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-25 {
    width: 25% !important;
  }
  .h-lg-100 {
    height: 100% !important;
  }
  .h-lg-75 {
    height: 75% !important;
  }
  .h-lg-50 {
    height: 50% !important;
  }
  .h-lg-25 {
    height: 25% !important;
  }
}

/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  .w-xl-100 {
    width: 100% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-25 {
    width: 25% !important;
  }
  .h-xl-100 {
    height: 100% !important;
  }
  .h-xl-75 {
    height: 75% !important;
  }
  .h-xl-50 {
    height: 50% !important;
  }
  .h-xl-25 {
    height: 25% !important;
  }
}
</style>
