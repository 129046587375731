<template>
  <b-modal 
    size="md" 
    centered 
    hide-footer 
    :visible="true" 
    no-close-on-esc
    no-close-on-backdrop 
    :title="`Profile info for ${member.name}`"
    @hide="hide"
  >
    <div>
      <b-row>
        <b-col cols="12" md="3">
          <h5>Name: </h5>
        </b-col>

        <b-col cols="12" md="9">
          <h4 class="font-weight-bold">{{ member.name }}</h4>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="3">
          <h5>Tel No: </h5>
        </b-col>

        <b-col cols="12" md="9">
          <h4 class="font-weight-bold">{{ member.tel }}</h4>
        </b-col>
      </b-row>

      <b-row class="mb-2">
        <b-col cols="12" md="3"><h5>Email: </h5></b-col>
        <b-col cols="12" md="9"><h4 class="font-weight-bold">{{ member.email }}</h4></b-col>
      </b-row>

      <!-- // eslint-disable-next-line vue/no-v-html -->
      <h4>Bio: </h4>
      <p class="font-md" v-html="member.bio" />
    </div>
  </b-modal>
</template>

<script>
import { BModal, BCardText, BRow, BCol, } from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BCardText
  },
  props: {
    member: {
      required: true,
      type: Object
    }
  },
  data() {
    return {}
  },
  methods: {
    hide() {
      console.log("hide")
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.font-md {
  font-size: 14px;
}
</style>
